import React, { createContext, useState } from 'react';

const defaultValues = {
  isPopupContactActive: false,
  setPopupContactActive: () => {},
};

export const SiteContext = createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [isPopupContactActive, setPopupContactActive] = useState(
    defaultValues.isPopupContactActive
  );

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,

        isPopupContactActive,
        setPopupContactActive,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
