const React = require('react');
const { SiteProvider } = require('./src/context/SiteContext');

exports.wrapRootElement = ({ element }) => (
  <SiteProvider>{element}</SiteProvider>
);

window.addEventListener('load', () => {
  setTimeout(function() {
    if (window.location.hash) {
      document
        .querySelector(window.location.hash)
        .scrollIntoView({ behavior: 'smooth' });
    }
  }, 2000);
});
